import {
  computed, Ref, ref, watch,
} from 'vue';
import { StandartizeApi } from '@/service/standartizeService';
import {
  mapTileDataFromResponse, Tile, TileData, TileDataRaw,
} from '@/service/api/reporting/tile';
import VNode from '@/components/vNode/VNode';
import { getGridElementConfig } from '@/pages/reporting/index.vue';
import { rChart, renderPlate } from '@/components/plate/plateIndicator/renders';
import { PlateIndicatorType } from '@/components/plate/plateIndicator';
import { StandarizeStatisticChartForm } from '@/pages/admin/statistic/main/Settings.vue';
import { DateTime } from 'luxon';
import { StandartizeChartTileLine } from '@/service/standartize/types';

export const statisticTypes = [
  'result_verified',
  'reg_court_verified',
  'mir_court_verified',
  'cadnum_verified',
  'reg_court_added',
  'mir_court_added',
  'fssp_added',
] as const;

export type StandarizeStatisticType = typeof statisticTypes;

export const namesMap = {
  result_verified: 'Потвержден стандартизированный адрес',
  reg_court_verified: 'Потверждена районная подсудность',
  mir_court_verified: 'Потверждена мировая подсудность',
  cadnum_verified: 'Потвержден кадастровый номер',
  reg_court_added: 'Добавлена районная подсудность',
  mir_court_added: 'Добавлена мировая подсудность',
  fssp_added: 'Добавлен ФССП',
} as const;

export const useCharts = (
  selectedUsers: Ref<number[]>,
  usersMap: Ref<Record<string, any>>,
  chartsSettings: Ref<StandarizeStatisticChartForm>,
  datePeriod: Ref<[null, null]|[Date, Date]>,
) => {
  const chartDataGeneral = ref<TileData>();
  const chartDataExtra = ref<TileData>();

  const datePeriodData = computed(() => {
    const [start, end] = datePeriod.value;
    if (!start || !end) {
      return null;
    }
    const now = DateTime.now().startOf('day');
    console.log('daysDiff', now.diff(DateTime.fromJSDate(start)));
    const daysStart = now.diff(DateTime.fromJSDate(start).plus({ days: -1 }).startOf('day')).as('days');
    const daysStop = now.diff(DateTime.fromJSDate(end).plus({ days: 1 }).startOf('day')).as('days');
    const stopDate = DateTime.fromJSDate(end).plus({ days: 1 });
    console.log('stopDate', stopDate);
    return {
      start: {
        days: Math.round(daysStart),
      },
      stop: DateTime.fromJSDate(end).plus({ days: 1 }).startOf('day').toFormat('yyyy-LL-dd HH:mm:ss'),
    };
  });

  // параметры верхнего графика
  const chartParametersGeneral = computed(() => {
    const lines = selectedUsers.value.reduce((acc, userId) => {
      const params = statisticTypes.map((type, i) => ({
        key: `group${userId}-part${i + 1}___${type}`,
        field: 'id',
        models: [
          {
            name: 'statistic_record',
            filters: {
              user_id: userId,
              type,
            },
            function: 'count',
            order_by: ['created_at'],
            // @ts-ignore
            split_by: {
              split_start: 'created_at',
              split_stop: 'created_at',
            },
          },
        ],
        // @ts-ignore
        split_by: {
          split_start: 'created_at',
          split_stop: 'created_at',
        },
      }) as StandartizeChartTileLine);
      acc.push(
        ...params,
      );
      return acc;
    }, [] as StandartizeChartTileLine[]);

    return {
      lines,
      ...(datePeriodData.value ? datePeriodData.value : {}),
    };
  });

  // параметры графика по времени
  const chartParametersExtra = computed(() => {
    const lines = selectedUsers.value.reduce((acc, userId) => {
      const type = chartsSettings.value.typeToDisplay;
      const params = [type].map((type, i) => ({
        key: `group${userId}-part${i + 1}___${type}`,
        field: 'id',
        models: [
          {
            name: 'statistic_record',
            filters: {
              user_id: userId,
              type,
            },
            function: 'count',
            order_by: ['created_at'],
            // @ts-ignore
            split_by: {
              split_start: 'created_at',
              split_stop: 'created_at',
            },
          },
        ],
        split_by: {
          split_start: 'created_at',
          split_stop: 'created_at',
        },
      }) as StandartizeChartTileLine);
      acc.push(
        ...params,
      );
      return acc;
    }, [] as StandartizeChartTileLine[]);

    return {
      lines,
      interval: {
        days: 1,
        weeks: 0,
        months: 0,
        years: 0,
      },
      round_dates: 'day',
      ...(datePeriodData.value ? datePeriodData.value : {
        start: { days: 30 },
      }),
    };
  });

  const chartConfigGeneral = computed<any>(() => {
    if (!chartDataGeneral.value) {
      return null;
    }
    const series: any[] = [];
    const xAxis = {
      type: 'category',
      axisTick: { show: false },
      hideOverlap: false,
      axisLabel: {
        formatter: (value: string) => value.split(' ').join('\n'),
      },
      data: statisticTypes.map((type) => namesMap[type]),
    };
    const yAxis = [
      {
        boundaryGap: false,
        axisTick: false,
        axisLine: false,
        axisLabel: {
          fontSize: '11px',
          align: 'left',
          color: 'rgba(94, 100, 118, 0.5)',
          fontWeight: 400,
          lineHeight: 21,
          margin: 40,
          z: 2,
        },
        z: 2,
        zLevel: 2,
      },
    ];

    selectedUsers.value.forEach((userId) => {
      const name = [
        `${usersMap.value[userId].username}`,
        usersMap.value[userId].email && `(${usersMap.value[userId].email})`,
      ].filter(Boolean).join(' ');
      const seriesElement = {
        name,
        type: 'bar',
        barGap: 0,
        emphasis: {
          focus: 'series',
        },
        data: [] as (number|null)[],
      };

      statisticTypes.forEach((type, i) => {
        const valueKey = `group${userId}-part${i + 1}___${type}`;
        const valRaw = chartDataGeneral.value?.summary?.[valueKey] ?? null;
        if (!valRaw) {
          seriesElement.data.push(null);
        } else {
          const val = typeof valRaw === 'number' ? valRaw : valRaw[0];
          seriesElement.data.push(val);
        }
      });

      series.push(seriesElement);
    });

    return {
      grid: {
        top: '40px',
        left: '40px',
        right: '35px',
        bottom: '80px',
        containLabel: false,
      },
      legend: {
        show: false,
        selectedMode: false,
        orient: 'horizontal',
        left: 'center',
        align: 'left',
        bottom: '0',
        icon: 'roundRect',
        lineStyle: {
          width: '100%',
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#e8edf4',
        },
      },
      tooltip: {
        trigger: 'axis',
        borderWidth: 0,
        backgroundColor: '#424857',
        textStyle: {
          color: 'white',
          fontSize: '12px',
          fontWeight: 400,
          width: '200px',
          display: 'block',
        },
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
        extraCssText: '\n    border-radius: 8px;\n    padding: 7px;\n    display: block;\n    max-width: 100%;\n    overflow: hidden;\n    white-space: break-spaces\n    ',
      },
      xAxis,
      yAxis,
      series,
    };
  });

  const chartConfigExtra = computed<any>(() => {
    if (!chartDataExtra.value) {
      return null;
    }
    console.log('chartConfigExtra', chartDataExtra.value);
    const series: any[] = [];
    const timeFormat = 'dd.LL.yyyy';
    const xAxis = {
      type: 'category',
      boundaryGap: false,
      axisTick: false,
      axisLine: false,
      axisLabel: {
        fontSize: '11px', color: 'rgba(94, 100, 118, 0.5)', fontWeight: 400, lineHeight: 21, margin: 15,
      },
      data: chartDataExtra.value?.time!.map(
        (d) => DateTime.fromJSDate(d).toFormat(timeFormat),
      ),
    };
    const yAxis = [
      {
        boundaryGap: false,
        axisTick: false,
        axisLine: false,
        axisLabel: {
          fontSize: '11px',
          align: 'left',
          color: 'rgba(94, 100, 118, 0.5)',
          fontWeight: 400,
          lineHeight: 21,
          margin: 40,
          z: 2,
        },
        z: 2,
        zLevel: 2,
      },
    ];

    selectedUsers.value.forEach((userId) => {
      const name = [
        `${usersMap.value[userId].username}`,
        usersMap.value[userId].email && `(${usersMap.value[userId].email})`,
      ].filter(Boolean).join(' ');
      const seriesElement = {
        name,
        type: 'bar',
        barGap: 0,
        emphasis: {
          focus: 'series',
        },
        data: [] as (number|null)[],
      };

      console.log('chartDataExtra.value?.summary', chartDataExtra.value);

      const type = chartsSettings.value.typeToDisplay;
      [type].forEach((type, i) => {
        const valueKey = `group${userId}-part${i + 1}___${type}`;
        chartDataExtra.value!.time!.forEach((_, i) => {
          const timeObj = chartDataExtra.value?.summary[valueKey] as Record<number, number>;
          seriesElement.data.push(
            timeObj[i],
          );
        });
      });

      series.push(seriesElement);
    });

    return {
      grid: {
        top: '40px',
        left: '40px',
        right: '35px',
        bottom: '80px',
        containLabel: false,
      },
      legend: {
        show: false,
        selectedMode: false,
        orient: 'horizontal',
        left: 'center',
        align: 'left',
        bottom: '0',
        icon: 'roundRect',
        lineStyle: {
          width: '100%',
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#e8edf4',
        },
      },
      tooltip: {
        trigger: 'axis',
        borderWidth: 0,
        backgroundColor: '#424857',
        textStyle: {
          color: 'white',
          fontSize: '12px',
          fontWeight: 400,
          width: '200px',
          display: 'block',
        },
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
        extraCssText: '\n    border-radius: 8px;\n    padding: 7px;\n    display: block;\n    max-width: 100%;\n    overflow: hidden;\n    white-space: break-spaces\n    ',
      },
      xAxis,
      yAxis,
      series,
    };
  });

  // const fetchChartsData = async () => {
  //   const [
  //     responseGeneral,
  //     responseExtra,
  //   ] = await Promise.all([
  //     StandartizeApi.statistic.getChartData(
  //       chartParametersGeneral.value,
  //     ),
  //     StandartizeApi.statistic.getChartData(
  //       chartParametersExtra.value,
  //     ),
  //   ]);
  //   if (responseGeneral.status) {
  //     chartDataGeneral.value = mapTileDataFromResponse(responseGeneral.response);
  //   }
  //   if (responseExtra.status) {
  //     chartDataExtra.value = mapTileDataFromResponse(responseExtra.response);
  //   }
  // };

  // const chartConfig = computed<Tile|null>(() => {
  //   if (!chartData.value) {
  //     return null;
  //   }
  //   return {
  //     data: chartData.value,
  //     parameters: {
  //       lines: chartParametersGeneral.value,
  //     },
  //     render_parameters: [
  //       chartRenderParameters.value,
  //     ],
  //   } as unknown as Tile;
  // });

  const renderedDataGeneral = computed(() => {
    if (!chartConfigGeneral.value) {
      return null;
    }
    const vnodes = renderPlate(
      'Сводная диаграмма',
      [],
      rChart({
        // @ts-ignore
        chartOptions: chartConfigGeneral.value,
        bodyClass: '',
        type: PlateIndicatorType.Pie,
        title: '',
      }),
    );
    return {
      renderProps: {
        vnodes,
      },
      component: VNode,
    };
  });

  const renderedDataExtra = computed(() => {
    if (!chartConfigExtra.value) {
      return null;
    }
    const typeToDisplay = chartsSettings.value.typeToDisplay;
    const vnodes = renderPlate(
      `Диаграмма за выбранный период - ${namesMap[typeToDisplay]}`,
      [],
      rChart({
        // @ts-ignore
        chartOptions: chartConfigExtra.value,
        bodyClass: '',
        type: PlateIndicatorType.Pie,
        title: '',
      }),
    );
    return {
      renderProps: {
        vnodes,
      },
      component: VNode,
    };
  });

  const gridConfig = ref(
    { rows: '11', cols: '5', rowSizes: '90px 150px 150px 150px 150px 300px 300px 300px 300px 300px 300px' },
  );

  const chartPositionConfigGeneral = {
    colStart: 1,
    colEnd: 6,
    rowStart: 1,
    rowEnd: 4,
  };

  const chartPositionConfigExtra = {
    colStart: 1,
    colEnd: 6,
    rowStart: 4,
    rowEnd: 6,
  };

  const gridElements = computed(() => [
    renderedDataGeneral.value && getGridElementConfig({ config: chartPositionConfigGeneral, id: 'general' } as Tile),
    renderedDataExtra.value && getGridElementConfig({ config: chartPositionConfigExtra, id: 'extra' } as Tile),
  ].filter(Boolean));

  const gridElementsData = computed(() => {
    const obj: Record<string, any> = {};
    if (renderedDataGeneral.value) {
      obj.general = renderedDataGeneral.value;
    }
    if (renderedDataExtra.value) {
      obj.extra = renderedDataExtra.value;
    }
    return obj;
  });

  watch([chartsSettings, selectedUsers, datePeriodData], () => {
    chartDataExtra.value = undefined;
    chartDataGeneral.value = undefined;
  }, { deep: true, immediate: true });

  return {
    gridElementsData,
    gridElements,
    gridConfig,
  };
};
